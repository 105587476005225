import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import ukUA from "antd/lib/locale/uk_UA";
import { swTaWaappImages } from "./serviceWorkers/swTaWaapp";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ConfigProvider
        locale={ukUA}
        theme={{
          token: {
            colorPrimary: "#050c30",
          },
        }}
      >
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// if (navigator.serviceWorker) {
//   window.addEventListener("load", () => {
//     swTaWaappImages();
//   });
// }
